//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary: #ff8a93; // Bootstrap variable
$primary-hover: #ff5764; // Custom variable
$primary-light: #ffffff; // Custom variable
$primary-inverse: #ffffff; // Custom variable

$secondary: #ffe5e5; // Custom variable
$secondary-hover: #facaca; // Custom variable
$secondary-active: #facaca; // Custom variable
$inspire: #393940;
