#classes-table col:nth-child(even) {
  background: #f0f0f0;
}
.border-bottom {
  color: "#b9b9b9";
}

#classes-table {
  border-collapse: separate;
  border-spacing: 0;
  /* overflow: auto; */
}

.dropdown-toggle::after {
  content: none !important;
}

#classes-table thead th:nth-child(even) {
  background: #f0f0f0 !important;
}
#classes-table thead th:nth-child(odd) {
  background: white !important;
}
/* sticky header */
#classes-table thead {
  position: sticky;
  top: 0;
  z-index: 2;
  /* border-bottom: 1px solid; */
}
#classes-table thead th {
  border-bottom: 1px solid;
}
/* sticky first row data */
#classes-table tbody tr td:nth-child(1) {
  background-color: white;
  position: sticky;
  left: 0;
  z-index: 1;
  border-right: 1px solid;
}
/* sticky first row/column */
#classes-table thead th:nth-child(1) {
  background-color: white;
  position: sticky;
  left: 0;
  border-right: 1px solid;
}
